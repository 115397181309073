var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.handlerResize,
          expression: "handlerResize"
        }
      ],
      staticClass: "commonInputBox",
      style: {
        border: _vm.inputShowError ? "1px solid #FB0000" : "1px solid #8F8F8F"
      }
    },
    [
      _c(
        "div",
        { staticClass: "inputIconBox", style: _vm.inputIconStyle },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        { staticClass: "inputInnerBox", style: _vm.inputInnerBoxStyle },
        [
          _c(
            "div",
            { staticClass: "inputBoxStyle" },
            [
              _vm.fixedText !== ""
                ? _c(
                    "span",
                    {
                      staticClass: "fixedTextBlock",
                      style: _vm.fixedTextBlockStyle
                    },
                    [_vm._v(_vm._s(`+${_vm.fixedText}`))]
                  )
                : _vm._e(),
              _c("div", { staticClass: "inputComponentBox" }, [
                (_vm.showPasswordStatus ? "password" : "text") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputText,
                          expression: "inputText"
                        }
                      ],
                      ref: "commonInputBoxCore",
                      style: {
                        "caret-color": _vm.colorStyle
                      },
                      attrs: {
                        placeholder: _vm.placeholderText,
                        autocomplete: "new-password",
                        readonly: _vm.readOnly,
                        maxlength: _vm.maxlength !== 0 ? _vm.maxlength : "auto",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.inputText)
                          ? _vm._i(_vm.inputText, null) > -1
                          : _vm.inputText
                      },
                      on: {
                        input: function($event) {
                          $event.stopPropagation()
                          return _vm.inputChange.apply(null, arguments)
                        },
                        blur: _vm.inputBlur,
                        change: function($event) {
                          var $$a = _vm.inputText,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.inputText = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.inputText = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.inputText = $$c
                          }
                        }
                      }
                    })
                  : (_vm.showPasswordStatus ? "password" : "text") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputText,
                          expression: "inputText"
                        }
                      ],
                      ref: "commonInputBoxCore",
                      style: {
                        "caret-color": _vm.colorStyle
                      },
                      attrs: {
                        placeholder: _vm.placeholderText,
                        autocomplete: "new-password",
                        readonly: _vm.readOnly,
                        maxlength: _vm.maxlength !== 0 ? _vm.maxlength : "auto",
                        type: "radio"
                      },
                      domProps: { checked: _vm._q(_vm.inputText, null) },
                      on: {
                        input: function($event) {
                          $event.stopPropagation()
                          return _vm.inputChange.apply(null, arguments)
                        },
                        blur: _vm.inputBlur,
                        change: function($event) {
                          _vm.inputText = null
                        }
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputText,
                          expression: "inputText"
                        }
                      ],
                      ref: "commonInputBoxCore",
                      style: {
                        "caret-color": _vm.colorStyle
                      },
                      attrs: {
                        placeholder: _vm.placeholderText,
                        autocomplete: "new-password",
                        readonly: _vm.readOnly,
                        maxlength: _vm.maxlength !== 0 ? _vm.maxlength : "auto",
                        type: _vm.showPasswordStatus ? "password" : "text"
                      },
                      domProps: { value: _vm.inputText },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) return
                            _vm.inputText = $event.target.value
                          },
                          function($event) {
                            $event.stopPropagation()
                            return _vm.inputChange.apply(null, arguments)
                          }
                        ],
                        blur: _vm.inputBlur
                      }
                    })
              ]),
              _vm.mailbox !== ""
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: _vm.mailbox,
                        placement: "top"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "mailboxBlock",
                          style: _vm.mailboxStyle
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.mailbox) + "\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.clickableSuffix !== ""
                ? _c(
                    "span",
                    {
                      staticClass: "clickableSuffixBlock",
                      style: _vm.mailboxStyle,
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.suffixClick.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.clickableSuffix))]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.showMaxTips && _vm.mailbox == "" && _vm.clickableSuffix == ""
            ? _c("div", { staticClass: "inputMaxTips" }, [
                _vm._v("\n      " + _vm._s(`(≤${_vm.maxlength})`) + "\n    ")
              ])
            : _vm._e(),
          _vm.checkAccountSuccess && _vm.inputText !== ""
            ? _c("div", { staticClass: "showAccountStatus" }, [
                _c("span", {
                  staticClass: "iconfont_Me icon-check iconStyle",
                  style: { color: _vm.colorStyle }
                })
              ])
            : _vm._e(),
          _vm.passwordInput
            ? _c(
                "div",
                {
                  staticClass: "showPasswordButton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeShowPasswordStatus.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.showPasswordStatus
                    ? _c("el-image", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: {
                          src: require("../../../assets/images/newEditCenter/canWatch.png"),
                          fit: "fill"
                        }
                      })
                    : _c("el-image", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: {
                          src: require("../../../assets/images/newEditCenter/canNotWatch.png"),
                          fit: "fill"
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }