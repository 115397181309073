<template>
  <div
    class="commonInputBox"
    :style="{
      border: inputShowError ? '1px solid #FB0000' : '1px solid #8F8F8F',
    }"
    v-resize-ob="handlerResize"
  >
    <div class="inputIconBox" :style="inputIconStyle">
      <slot></slot>
    </div>
    <div class="inputInnerBox" :style="inputInnerBoxStyle">
      <!-- 输入框区域 -->
      <div class="inputBoxStyle">
        <!-- 头部固定文本 -->
        <span
          class="fixedTextBlock"
          :style="fixedTextBlockStyle"
          v-if="fixedText !== ''"
          >{{ `+${fixedText}` }}</span
        >
        <!-- 输入框 -->
        <div class="inputComponentBox">
          <input
            v-model="inputText"
            :placeholder="placeholderText"
            @input.stop="inputChange"
            autocomplete="new-password"
            :type="showPasswordStatus ? 'password' : 'text'"
            :readonly="readOnly"
            :maxlength="maxlength !== 0 ? maxlength : 'auto'"
            @blur="inputBlur"
            :style="{
              'caret-color': colorStyle,
            }"
            ref="commonInputBoxCore"
          />
        </div>
        <el-tooltip
          effect="dark"
          :content="mailbox"
          placement="top"
          v-if="mailbox !== ''"
        >
          <!-- 这个是邮箱域名后缀 -->
          <span class="mailboxBlock" :style="mailboxStyle">
            {{ mailbox }}
          </span>
        </el-tooltip>
        <!-- 这个是点击后缀 -->
        <span
          class="clickableSuffixBlock"
          :style="mailboxStyle"
          v-if="clickableSuffix !== ''"
          @click.stop="suffixClick"
          >{{ clickableSuffix }}</span
        >
      </div>
      <!-- 这个是输入框的最大长度 -->
      <div
        v-if="showMaxTips && mailbox == '' && clickableSuffix == ''"
        class="inputMaxTips"
      >
        {{ `(≤${maxlength})` }}
      </div>
      <!-- 这个是验证输入的值，成功后展示的√ -->
      <div
        v-if="checkAccountSuccess && inputText !== ''"
        class="showAccountStatus"
      >
        <span
          class="iconfont_Me icon-check iconStyle"
          :style="{ color: colorStyle }"
        ></span>
      </div>
      <!-- 这是是否显示密码的按钮 -->
      <div
        v-if="passwordInput"
        class="showPasswordButton"
        @click.stop="changeShowPasswordStatus"
      >
        <el-image
          v-if="showPasswordStatus"
          style="width: 16px; height: 16px"
          :src="require('../../../assets/images/newEditCenter/canWatch.png')"
          :fit="'fill'"
        ></el-image>
        <el-image
          v-else
          style="width: 16px; height: 16px"
          :src="require('../../../assets/images/newEditCenter/canNotWatch.png')"
          :fit="'fill'"
        ></el-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 限制输入类型
    onlyType: {
      type: String,
      default: "all",
    },
    // 输入规则
    noChinese: {
      type: Boolean,
      default: false,
    },
    // 是否是密码输入框
    passwordInput: {
      type: Boolean,
      default: false,
    },
    // 默认显示
    placeholderText: {
      type: String,
      default: "",
    },
    // 账号验证成功
    checkAccountSuccess: {
      type: Boolean,
      default: false,
    },
    // 需要将密码框设置错误提醒
    needInputshowError: {
      type: Boolean,
      default: false,
    },
    // 默认文本
    defaultInputText: {
      type: String,
      default: "",
    },
    // 禁止修改
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 头部固定展示的文本
    fixedText: {
      type: String,
      default: "",
    },
    // 输入框最大长度
    maxlength: {
      type: Number,
      default: 0,
    },
    // 邮箱域名默认填充
    mailbox: {
      type: String,
      default: "",
    },
    // 主体颜色
    colorStyle: {
      type: String,
      default: "#33CC66",
    },
    // 可点击后缀
    clickableSuffix: {
      type: String,
      default: "",
    },
    // 强制输入内容
    forceInputText: {
      type: String,
      default: "",
    },
  },
  watch: {
    // 监听默认文本改变,如果新值变为空，则将输入框清空
    defaultInputText: {
      handler(val, old) {
        if (val === "" && val !== old) {
          this.inputText = "";
        }
      },
      deep: true,
    },
    // 监听强制输入内容改变
    forceInputText: {
      handler(val, old) {
        if (val !== old && val !== "") {
          this.inputText = val;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 是否是密码输入框
      showPasswordStatus: this.passwordInput,
      // 输入的文本
      inputText: this.defaultInputText,
      // 宽度监听
      widthListener: 0,
      // 标准宽度
      originalWidth: 360,
      // 正在合成
      isComposing: false,
    };
  },
  computed: {
    // 只能输入数字
    onlyNumber() {
      return this.onlyType == "number";
    },
    // 输入框显示最大输入长度提示
    showMaxTips() {
      return this.maxlength !== 0 && this.inputText === "";
    },
    // 输入框展示错误提示
    inputShowError() {
      return this.needInputshowError && this.inputText !== "";
    },
    handlerFontSize() {
      let calculatedFontSize = this.realTimeComputing(14);
      if (calculatedFontSize < 12) {
        calculatedFontSize = 12;
      }
      return calculatedFontSize;
    },
    inputInnerBoxStyle() {
      return {
        padding: `0 ${this.realTimeComputing(3)}px 0 ${this.realTimeComputing(
          5
        )}px`,
        "font-size": `${this.handlerFontSize}px`,
      };
    },
    fixedTextBlockStyle() {
      return {
        padding: `0 ${this.realTimeComputing(15)}px 0 0`,
        "font-size": `${this.handlerFontSize}px`,
        color: this.colorStyle,
      };
    },
    inputIconStyle() {
      return {
        width: `${this.realTimeComputing(24)}px`,
        height: `${this.realTimeComputing(24)}px`,
        padding: `0 ${
          this.realTimeComputing(10) < 8 ? 8 : this.realTimeComputing(10)
        }px`,
      };
    },
    // 邮箱尾
    mailboxStyle() {
      return {
        padding: `0 ${this.realTimeComputing(5)}px`,
        "font-size": `${this.handlerFontSize}px`,
      };
    },
  },
  mounted() {
    try {
      if (this.$refs.commonInputBoxCore) {
        const inp = this.$refs.commonInputBoxCore;
        // 开始输入合成字符
        inp.addEventListener("compositionstart", this.compositionstart);
        // 结束输入合成字符
        inp.addEventListener("compositionend", this.compositionend);
        // 处理粘贴事件
        inp.addEventListener("paste", this.paste);
        // 处理输入事件
        inp.addEventListener("keypress", this.keypress);
      }
    } catch (error) {}
  },
  beforeDestroy() {
    try {
      if (this.$refs.commonInputBoxCore) {
        const inp = this.$refs.commonInputBoxCore;
        inp.removeEventListener("compositionstart", this.compositionstart);
        inp.removeEventListener("compositionend", this.compositionend);
        inp.removeEventListener("paste", this.paste);
        inp.removeEventListener("keypress", this.keypress);
      }
    } catch (error) {}
  },
  methods: {
    compositionstart() {
      this.isComposing = true;
    },
    compositionend(e) {
      this.isComposing = false;
      // 如果是禁止输入中文或者只能输入数字，则需要将中文去掉
      if (
        (this.noChinese || this.onlyNumber) &&
        /[\u4e00-\u9fa5]/.test(e.data)
      ) {
        this.inputText = e.target.value.slice(0, -e.data.length);
      }
      this.inputChange();
    },
    paste(e) {
      const text = e.clipboardData.getData("text/plain");
      if (this.noChinese && /[\u4e00-\u9fa5]/.test(text)) {
        e.preventDefault();
      }
      if (this.onlyNumber && !/^\d/.test(e.key)) {
        e.preventDefault();
      }
    },
    keypress(e) {
      // 过滤空格
      if (/\s+/.test(e.key)) {
        e.preventDefault();
      }
      // 如果只能输入数字，则需要过滤非数字字符
      if (this.onlyNumber && !/^\d/.test(e.key)) {
        e.preventDefault();
      }
    },
    suffixClick() {
      this.$emit("suffixClick");
    },
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    // 监听大小改变动态改变样式
    handlerResize(resize) {
      this.widthListener = resize.width;
    },
    // 动态改变显示隐藏输入的文本
    changeShowPasswordStatus() {
      this.showPasswordStatus = !this.showPasswordStatus;
    },
    // 输入框内容改变
    async inputChange() {
      if (this.isComposing) {
        return;
      }
      this.$emit("inputChange", this.inputText);
    },
    // 失去焦点处理
    inputBlur() {
      this.handleInputOverflow();
    },
  },
};
</script>
<style lang="stylus" scoped>
.commonInputBox
  width 100%;
  height 100%;
  overflow hidden;
  border-radius 4px;
  box-sizing border-box;
  display flex;
  align-items center;
  justify-content space-between;
  .inputIconBox
    flex-shrink 0;
    display flex;
    align-items center;
    justify-content center;
    border-right 1px solid #E6E6E6;
    box-sizing: content-box;
    min-width: 19px;
    min-height: 19px;
  .inputInnerBox
    flex 1;
    height 100%;
    min-width 0px;
    box-sizing border-box;
    display flex;
    align-items center;
    justify-content space-between;
    position relative;
    .inputBoxStyle
      flex 1;
      min-width 0;
      height 100%;
      display flex;
      align-items center;
      justify-content flex-start;
      .inputComponentBox
        flex: 1;
        max-width: 100%;
      .fixedTextBlock
        white-space nowrap;
        flex-shrink: 0;
      .mailboxBlock
        white-space nowrap;
        flex-shrink: 0;
        color: #666666;
        display: inline-block;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      .clickableSuffixBlock
        white-space nowrap;
        flex-shrink: 0;
        color: #52BD68;
        cursor: pointer;
        user-select: none;
      input
        width 100%;
        height 100%;
        color #343434;
        border none;
        outline none;
        &::-webkit-input-placeholder
          color: #CCCCCC;
        &::-moz-input-placeholder
          color: #CCCCCC;
        &::-ms-input-placeholder
          color: #CCCCCC;
    .inputMaxTips
      top 0;
      right 0;
      height 100%;
      min-width 0;
      position absolute;
      display inline-block;
      color #CCCCCC;
      display flex;
      align-items center;
      box-sizing border-box;
      padding 0 10px;
    .showAccountStatus
      width 30px;
      height 30px;
      flex-shrink 0;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 20px;
    .showPasswordButton
      width 30px;
      height 30px;
      cursor pointer;
      flex-shrink 0;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 20px;
</style>
